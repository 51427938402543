import React from 'react';
import { func } from 'prop-types';
import { navigate } from 'gatsby';

import { REGISTRATION_START } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmilePlus } from '@fortawesome/pro-regular-svg-icons';

import { Button, FormHelperText } from '@material-ui/core';
import useStyles, { useButtonClasses } from './styles';

const RegisterNavMessage = ({ onAction = () => {} }) => {
  const styles = useStyles();
  const buttonClasses = useButtonClasses();

  return (
  <>
    <Button
      classes={buttonClasses}
      variant="outlined"
      aria-label="Resiter with Total Loyalty"
      className={styles.messageButton}
      onClick={() => navigate(REGISTRATION_START)}
      color="primary"
      >
      <FontAwesomeIcon icon={faSmilePlus} className={styles.menuIcon} size="lg"/>
      Register
    </Button>
    <FormHelperText className={styles.helpText}>
      Create an account with Total Loyalty
    </FormHelperText>
  </>
  );
};

RegisterNavMessage.propTypes = { onAction: func };

export default RegisterNavMessage;
